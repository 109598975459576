import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import Carousel from "nuka-carousel";
import useWindowSize from "../../hooks/use-window-size";
import EntrySlides from "./EntrySlides";
import chevron from "../../images/svg/chevron-right-blue.svg";
import bg from "../../images/home-section/business-types-bg.png";
import BusinessTypesStyles from "./BusinessTypesStyles";

const { Title } = Typography;

const BusinessTypes = (props) => {
  const { sectionData } = props;
  const [numberOfSlides, setNumberOfSlides] = useState(3);
  const [cellSpacing, setCellSpacing] = useState(10);
  const size = useWindowSize();

  useEffect(() => {
    const { width } = size;
    const isMobileOnly = width <= 576;
    const isTablet = width > 576 && width < 992;
    const isSreenLG = width > 992 && width < 1200;
    const screenXL = width > 1200;

    const slides = () => {
      if (isMobileOnly) return 1.5;
      if (isTablet) return 1.5;
      if (isSreenLG) return 2;
      if (screenXL) return 2;
      return 2;
    };
    const spacing = () => {
      if (isMobileOnly) return 20;
      if (isTablet) return 60;
      return 100;
    };

    setNumberOfSlides(slides);
    setCellSpacing(spacing);
  }, [size]);
  return (
    <section className="business-types" style={{ background: `url(${bg})` }}>
      {sectionData.title && (
        <Row>
          <Col lg={10} md={16} sm={24}>
            <div>
              <Title level={2}>
                {sectionData.title}
                <span className="blue_dot">{`${
                  sectionData.punctuation || "."
                }`}</span>{" "}
              </Title>
              <p className="normal-paragraph">{sectionData.subtext}</p>
            </div>
          </Col>
        </Row>
      )}
      <Carousel
        slidesToShow={sectionData.slides.length <= 1 ? 1 : numberOfSlides}
        cellSpacing={cellSpacing}
        enableKeyboardControls
        renderCenterLeftControls={null}
        renderBottomCenterControls={null}
        renderCenterRightControls={({ nextSlide }) =>
          sectionData.slides.length > 1 && (
            <button
              type="button"
              className="btn-right"
              onClick={nextSlide}
              aria-label="Next Slide"
            >
              <img src={chevron} alt="" />
              <p className="eye-not-visible">Next Slide</p>
            </button>
          )
        }
        renderCenterLeftControls={({ previousSlide }) =>
          sectionData.slides.length > 1 && (
            <button
              type="button"
              className="btn-left"
              onClick={previousSlide}
              aria-label="Previous Slide"
            >
              <img src={chevron} alt="" />
              <p className="eye-not-visible">Previous Slide</p>
            </button>
          )
        }
        dragging
        wrapAround
      >
        {sectionData.slides.map((slide, idx) => (
          <EntrySlides
            isOne={sectionData.slides.length <= 1}
            key={idx.toString()}
            id={idx.toString()}
            cardTitle={slide.cardTitle}
            cardContent={slide.cardContent}
            bg={slide.bg}
            cardIcon={slide.icon}
            linkTo={slide.linkTo}
          />
        ))}
      </Carousel>

      <BusinessTypesStyles />
    </section>
  );
};

BusinessTypes.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
BusinessTypes.defaultProps = {
  sectionData: "",
};

export default BusinessTypes;
