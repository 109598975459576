import React from "react";
import { colors, viewports } from "../../style-vars";

const { mdOrSmaller, lgOrBigger, smOrSmaller } = viewports;
const { white, black } = colors;

const BusinessTypesStyles = () => (
  <style jsx global>{`
    .business-types {
      position: relative;
      max-width: 1440px;
      width: 100%;
      background-repeat: no-repeat !important;
      background-position-y: 50% !important;
      padding: 64px 100px 0px 100px;
    }
    @media ${mdOrSmaller} {
      .business-types {
        padding: 64px 32px 120px 32px;
        background-position: top center !important;
      }
    }
    @media ${mdOrSmaller} {
      .business-types {
        padding: 64px 32px 60px 32px;
      }
    }
    .business-types p {
      font-size: 16px !important;
      font-weight: 400;
      line-height: 2 !important;
      margin-top: 24px !important;
    }
    .business-types .btn-right {
      width: 64px;
      height: 64px;
      transform: translateX(4em);
    }
    @media ${mdOrSmaller} {
      .business-types .btn-right {
        display: none;
      }
    }
    .business-types .btn-left {
      width: 64px;
      height: 64px;
      transform: translateX(-4em);
    }
    .business-types .btn-left img {
      transform: rotate(180deg);
    }
    @media ${mdOrSmaller} {
      .business-types .btn-left {
        display: none;
      }
    }
    .business-types .btn-left,
    .business-types .btn-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .business-types_a {
      position: relative;
      max-width: 1440px;
      width: 100%;
      background-repeat: no-repeat !important;
      background-position-y: 50% !important;
      padding: 64px 24px 0px 24px;
    }
    @media ${mdOrSmaller} {
      .business-types_a {
        padding: 64px 32px 120px 32px;
        background-position: top center !important;
      }
    }
    @media ${mdOrSmaller} {
      .business-types_a {
        padding: 64px 32px 60px 32px;
      }
    }
    .business-types_a p {
      font-size: 16px !important;
      font-weight: 400;
      line-height: 2 !important;
      margin-top: 24px !important;
    }
    .business-types_a .btn-right {
      width: 64px;
      height: 64px;
      transform: translateX(3.5em);
    }
    @media ${mdOrSmaller} {
      .business-types_a .btn-right {
        display: none;
      }
    }
    .business-types_a .btn-left {
      width: 64px;
      height: 64px;
      transform: translateX(-3.5em);
      border-radius: 84px;
      background: rgba(23, 105, 255, 0.1);
      border: none;
      display: inline-block;
    }
    @media ${mdOrSmaller} {
      .business-types_a .btn-left {
        display: none;
      }
    }
    .business-slide {
      position: relative;
      max-height: 750px;
    }
    .business-slide img {
      width: 100%;
      max-height: 40em;
      padding: 10px;
      object-fit: scale-down !important;
      transition: ease-in-out 1s;
    }
    .circle-icon img {
      padding: 0 !important;
    }
    @media ${lgOrBigger} {
      .business-slide img {
        max-height: 30em;
      }
    }
    .business-slide__card {
      position: absolute;
      padding: 25px;
      right: 3%;
      top: 50%;
      z-index: 99;
      background: ${white};
      border-radius: 8px;
      box-shadow: 0px 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
      width: 100%;
      max-width: 350px;
      max-height: 200px;
    }
    .business-slide__card--mod {
      position: absolute !important;
      z-index: 99;
      left: 50% !important;
      top: 50% !important;
      transform: translateX(-50%) translateY(-50%);
      background: ${white};
      border-radius: 8px;
      box-shadow: 0px 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
      width: 100%;
      max-width: 350px !important;
      max-height: 200px;
      padding: 25px;
    }
    .business-slide__card .gatsby-image-wrapper {
      max-width: 60px !important;
      margin-bottom: 0px;
    }
    @media ${smOrSmaller} {
      .business-slide__card {
        position: relative;
        padding: 25px;
        min-height: 280px;
        max-height: 100% !important;
        transform: translateX(20px) translateY(-60px);
      }
    }
    .business-slide__card img {
      width: 32px;
      height: 32px;
      margin-bottom: 16px;
      transform: none;
    }
    .business-slide__card span {
      color: ${black};
      font-weight: 800;
      font-size: 18px;
      margin-bottom: 0;
    }
    .business-slide__card p {
      margin-top: 0 !important;
      color: #748093;
      font-size: 16px !important;
      margin-right: 16px !important;
    }
    .slide-current .business-slide img {
      transform: scale(0.9);
      transition: ease-in-out 1s;
    }
    @media ${mdOrSmaller} {
      .slide-current .business-slide img {
        transform: none;
      }
    }
    .slide-current .business-slide__card {
      position: absolute;
      padding: 25px;
      right: -10%;
      top: 50%;
      z-index: 99;
      background: ${white};
      border-radius: 8px;
      box-shadow: 0px 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
      width: 100%;
      max-width: 350px;
      max-height: 200px;
    }
    .slide-current .business-slide__card .gatsby-image-wrapper {
      max-width: 60px !important;
      margin-bottom: 0px;
    }
    @media ${smOrSmaller} {
      .business-slide__card {
        position: relative !important;
      }
      .slide-current .business-slide__card {
        position: relative;
        padding: 25px;
        max-height: 100%;
        transform: translateX(-20px) translateY(-60px);
        top: 0;
      }
    }
    .slide-current .business-slide_a img {
      transform: scale(1);
      transition: ease-in-out 1s;
    }
    @media ${mdOrSmaller} {
      .slide-current .business-slide_a img {
        transform: none;
      }
    }
    .slide-current .business-slide_a__card_a {
      position: absolute;
      padding: 15px 25px;
      right: 4% !important;
      top: 67%;
      z-index: 99;
      background: ${white};
      border-radius: 8px;
      box-shadow: 0px 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
      width: 100%;
      max-width: 255px;
      max-height: 160px;
    }
    .slide-current .business-slide_a__card_a p {
      margin-top: 0 !important;
      color: #748093;
      font-size: 12px !important;
      margin-right: 16px !important;
    }
    .slide-current .business-slide_a__card_a .gatsby-image-wrapper {
      max-width: 60px !important;
      margin-bottom: 0px;
    }
    @media ${smOrSmaller} {
      .slide-current .business-slide_a__card_a {
        position: relative;
        padding: 25px;
        max-height: 100%;
        transform: translateX(-20px) translateY(-60px);
        margin-left: 36px;
        max-width: 235px !important;
      }
    }
  `}</style>
);

export default BusinessTypesStyles;
