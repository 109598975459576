import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Typography } from "antd";
import Image from "../ImageQuerys/CircleEntries";
import ImageIcon from "../ImageQuerys/IconQueries";
import { colors, viewports } from "../../style-vars";

const { Title } = Typography;
const { smOrSmaller, mdOrBigger, lgOrBigger } = viewports;

const EntrySlides = (props) => {
  const { bg, cardTitle, cardContent, cardIcon, id, linkTo, isOne } = props;
  return (
    <div className="business-slide" key={id.toString()}>
      {linkTo && (
        <>
          <Link to={linkTo}>
            <Image imageName={bg} />
            <div
              className={`business-slide__card ${
                isOne && "business-slide__card--mod"
              }`}
            >
              {cardIcon && (
                <ImageIcon imageName={cardIcon} className="circle-icon" />
              )}
              <span>{cardTitle}</span>
              <p>{cardContent}</p>
            </div>
          </Link>
        </>
      )}
      {!linkTo && (
        <div className="business-slide__card">
          <ImageIcon imageName={cardIcon} />
          <Title level={5}>{cardTitle}</Title>
          <p>{cardContent}</p>
        </div>
      )}

      <style jsx>{`
        .business-slide {
          position: relative;
          max-height: 750px;
        }

        .business-slide :global(img) {
          width: 100%;
          max-height: 40em;
          padding: 10px;
          object-fit: scale-down !important;
          transition: ease-in-out 3s;
        }

        .business-slide__card {
          position: absolute;
          padding: 25px;
          right: 8%;
          top: 50%;
          z-index: 99;
          background: ${colors.white};
          border-radius: 8px;
          box-shadow: 0 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
          width: 100%;
          max-width: 350px;
          max-height: 200px;
        }

        .business-slide__card--mod {
          position: absolute !important;
          z-index: 99;
          left: 50% !important;
          top: 50% !important;
          transform: translateX(-50%) translateY(-50%);
          background: ${colors.white};
          border-radius: 8px;
          box-shadow: 0 21.2459px 65.0656px rgba(1, 44, 109, 0.16);
          width: 100%;
          max-width: 350px !important;
          max-height: 200px;
          padding: 25px;
        }

        .business-slide .business-slide__card > :global(.gatsby-image-wrapper) {
          max-width: 60px !important;
          margin-bottom: 0;
        }

        .business-slide__card > :global(.gatsby-image-wrapper img) {
          width: 32px;
          height: 32px;
          margin-bottom: 16px;
          transform: none;
        }

        .business-slide__card > :global(h5) {
          color: ${colors.black};
          font-weight: 800;
          font-size: 18px;
          margin-bottom: 0;
        }

        .business-slide__card p {
          margin-top: 0 !important;
          color: ${colors.paragraph};
          font-size: 16px !important;
          margin-right: 16px !important;
        }

        @media ${smOrSmaller} {
          .business-slide__card--mod {
            position: relative;
            padding: 25px;
            max-height: 100%;
            transform: translateX(20px) translateY(-60px);
          }
        }

        @media ${mdOrBigger} {
        }

        @media ${lgOrBigger} {
          .business-slide :global(img) {
            max-height: 30em;
          }
        }
      `}</style>
    </div>
  );
};

EntrySlides.propTypes = {
  bg: PropTypes.string,
  cardTitle: PropTypes.string,
  cardContent: PropTypes.string,
  cardIcon: PropTypes.string,
  id: PropTypes.string,
  linkTo: PropTypes.string,
  isOne: PropTypes.bool,
};
EntrySlides.defaultProps = {
  bg: "",
  cardTitle: "",
  cardContent: "",
  cardIcon: "",
  id: "",
  linkTo: "",
  isOne: false,
};

export default EntrySlides;
